<template>
  <div class="trainingDiagnosis">
    <ul class="medd-content pad-10">
      <li></li>
      <li>裸眼视力</li>
      <li>球镜</li>
      <li>柱镜</li>
      <li>轴向</li>
      <li>矫正视力</li>
      <li>主导眼</li>
    </ul>
    <ul class="medd-content">
      <li>OD</li>
      <li>
        <el-select  filterable allow-create v-model="datas.S_OP_Json.S_OP_VTFT_UCVA_OD" placeholder="请选择">
          <el-option
              v-for="item in lysl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li><el-input v-model="datas.S_OP_Json.S_OP_VTFT_DS_OD" placeholder="请输入内容"></el-input></li>
      <li><el-input v-model="datas.S_OP_Json.S_OP_VTFT_DC_OD" placeholder="请输入内容"></el-input></li>
      <li><el-input v-model="datas.S_OP_Json.S_OP_VTFT_AX_OD" placeholder="请输入内容"></el-input></li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_VA_OD" placeholder="请选择">
          <el-option
              v-for="item in lysl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li style="position: relative; top: 25px;">
        <el-select filterable allow-create v-model="datas.S_OP_Json.S_OP_VTFT_leadingEye" placeholder="请选择">
          <el-option
              v-for="item in zdy"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
    </ul>
    <ul class="medd-content">
      <li>OS</li>
      <li>
        <el-select filterable allow-create v-model="datas.S_OP_Json.S_OP_VTFT_UCVA_OS" placeholder="请选择">
          <el-option
              v-for="item in lysl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li><el-input v-model="datas.S_OP_Json.S_OP_VTFT_DS_OS" placeholder="请输入内容"></el-input></li>
      <li><el-input v-model="datas.S_OP_Json.S_OP_VTFT_DC_OS" placeholder="请输入内容"></el-input></li>
      <li><el-input v-model="datas.S_OP_Json.S_OP_VTFT_AX_OS" placeholder="请输入内容"></el-input></li>
      <li>
        <el-select filterable allow-create v-model="datas.S_OP_Json.S_OP_VTFT_VA_OS" placeholder="请选择">
          <el-option
              v-for="item in lysl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
    </ul>

    <ul class="medd-content pad-10">
      <li></li>
      <li>注视性质</li>
      <li>弱视类型</li>
      <li>晶状体</li>
      <li>眼位</li>
      <li>worth4</li>
    </ul>
    <ul class="medd-content">
      <li>OD</li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_gazeProperties_OD" placeholder="请选择">
          <el-option
              v-for="item in zsxz"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_weakSight_OD" placeholder="请选择">
          <el-option
              v-for="item in rslx"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_crystal_OD" placeholder="请选择">
          <el-option
              v-for="item in jzt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li style="position: relative; top: 25px;">
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_position" placeholder="请选择">
          <el-option
              v-for="item in yw"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li style="position: relative; top: 25px;">
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_worth4" placeholder="请选择">
          <el-option
              v-for="item in worth"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
    </ul>
    <ul class="medd-content">
      <li>OS</li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_gazeProperties_OS" placeholder="请选择">
          <el-option
              v-for="item in zsxz"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_weakSight_OS" placeholder="请选择">
          <el-option
              v-for="item in rslx"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="datas.S_OP_Json.S_OP_VTFT_crystal_OS" placeholder="请选择">
          <el-option
              v-for="item in jzt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
    </ul>

    <div class="yqzd">
      <p>眼球震颤</p>
      <el-radio class="radio" v-model="datas.S_OP_Json.S_OP_VTFT_eyedTremble" label="0">是</el-radio>
      <el-radio class="radio" v-model="datas.S_OP_Json.S_OP_VTFT_eyedTremble" label="1">否</el-radio>
    </div>

    <div class="yqzd">
      <p>相关检查</p>
      <el-button type="primary">同视机检查</el-button>
      <el-button type="primary">在线三级视功能检查</el-button>
    </div>
    <div class="yqzd">
      <p>诊断结论</p>
      <el-select v-model="datas.S_OP_Json.S_OP_VTFT_conclusion" placeholder="请选择">
        <el-option
            v-for="item in zdjl"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="yqzd">
      <p>训练类型</p>
      <el-select v-model="datas.S_OP_Json.S_OP_VTFT_trainType" placeholder="请选择">
        <el-option
            v-for="item in xllx"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div style="padding-bottom: 8vh">
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "trainingDiagnosis",
  data() {
    return {
      lysl: [],
      lyslOD: '',
      lyslOS: '',
      jzslOD: '',
      jzslOS: '',

      zdy: [
        {
          value: '右',
          label: '右'
        },
        {
          value: '左',
          label: '左'
        },
        {
          value: '交替',
          label: '交替'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      zdyVal: '',

      input: '',

      zsxz: [
        {
          value: '中心注视(0-1°)',
          label: '中心注视(0-1°)'
        },
        {
          value: '中心注视(1-3°)',
          label: '中心注视(1-3°)'
        },
        {
          value: '中心注视(3-5°)',
          label: '中心注视(3-5°)'
        },
        {
          value: '中心注视(>5°)',
          label: '中心注视(>5°)'
        },
        {
          value: ' ',
          label: ' '
        },
      ],
      zsxzOD: '',
      zsxzOS: '',

      rslx: [
        {
          value: '视力正常',
          label: '视力正常'
        },
        {
          value: '屈光不正性弱视',
          label: '屈光不正性弱视'
        },
        {
          value: '屈光参差性弱视',
          label: '屈光参差性弱视'
        },
        {
          value: '斜视性弱视',
          label: '斜视性弱视'
        },
        {
          value: '形觉剥夺性弱视',
          label: '形觉剥夺性弱视'
        },
        {
          value: ' ',
          label: ' '
        },
      ],
      rslxOD: '',
      rslxOS: '',

      jzt: [
        {
          value: '透明',
          label: '透明'
        },
        {
          value: '无晶状体',
          label: '无晶状体'
        },
        {
          value: '人工晶状体',
          label: '人工晶状体'
        },
        {
          value: '异位或脱位',
          label: '异位或脱位'
        },
        {
          value: '浑浊',
          label: '浑浊'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      jztOD: '',
      jztOS: '',

      yw: [
        {
          value: '内斜',
          label: '内斜'
        },
        {
          value: '内隐斜',
          label: '内隐斜'
        },
        {
          value: '外斜',
          label: '外斜'
        },
        {
          value: '外隐斜',
          label: '外隐斜'
        },
        {
          value: '正位视',
          label: '正位视'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      ywVal: '',

      worth: [
        {
          value: '左眼抑制',
          label: '左眼抑制'
        },
        {
          value: '右眼抑制',
          label: '右眼抑制'
        },
        {
          value: '正常',
          label: '正常'
        },
        {
          value: '复视',
          label: '复视'
        },
        {
          value: '交替抑制',
          label: '交替抑制'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      worthVal: '',

      zdjl: [
        {
          value: '屈光不正性弱视',
          label: '屈光不正性弱视'
        },
        {
          value: '屈光参差性弱视',
          label: '屈光参差性弱视'
        },
        {
          value: '斜视性弱视',
          label: '斜视性弱视'
        },
        {
          value: '形觉剥夺性弱视',
          label: '形觉剥夺性弱视'
        },
        {
          value: '内斜视',
          label: '内斜视'
        },
        {
          value: '外斜视',
          label: '外斜视'
        },
        {
          value: '上斜视',
          label: '上斜视'
        },
        {
          value: '下斜视',
          label: '下斜视'
        },
        {
          value: '屈光不正性视疲劳',
          label: '屈光不正性视疲劳'
        },
        {
          value: '老视性疲劳',
          label: '老视性疲劳'
        },
        {
          value: '肌性视疲劳',
          label: '肌性视疲劳'
        },
        {
          value: '职业性视疲劳',
          label: '职业性视疲劳'
        },
        {
          value: ' ',
          label: ' '
        },
      ],
      zdjlVal: '',

      xllx: [
        {
          value: '提升期',
          label: '提升期'
        },
        {
          value: '巩固期',
          label: '巩固期'
        },
        {
          value: '体验期',
          label: '体验期'
        },
        {
          value: '斜视手术',
          label: '斜视手术'
        },
        {
          value: '视疲劳',
          label: '视疲劳'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      xllxVal: '',

      radio: '',

      datas: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          S_OP_VTFT_AX_OD: "",
          S_OP_VTFT_AX_OS: "",
          S_OP_VTFT_ChooseID: "",
          S_OP_VTFT_DC_OD: "",
          S_OP_VTFT_DC_OS: "",
          S_OP_VTFT_DS_OD: "",
          S_OP_VTFT_DS_OS: "",
          S_OP_VTFT_UCVA_OD: "",
          S_OP_VTFT_UCVA_OS: "",
          S_OP_VTFT_VA_OD: "",
          S_OP_VTFT_VA_OS: "",
          S_OP_VTFT_conclusion: "",
          S_OP_VTFT_crystal_OD: "",
          S_OP_VTFT_crystal_OS: "",
          S_OP_VTFT_eyedTremble: "",
          S_OP_VTFT_gazeProperties_OD: "",
          S_OP_VTFT_gazeProperties_OS: "",
          S_OP_VTFT_leadingEye: "",
          S_OP_VTFT_position: "",
          S_OP_VTFT_trainType: "",
          S_OP_VTFT_weakSight_OD: "",
          S_OP_VTFT_weakSight_OS: "",
          S_OP_VTFT_worth4: ""
        },
        S_OP_Model: "VisionTrainForTopEye201105",
        S_OP_Time: "",
        S_OP_Type: "VisionTrainForTopEye",
        S_OP_XML: ""
      }
    }
  },

  watch: {
    "datas": {
      deep: true,
      handler(o, n) {
        if (o) {
          this.$store.commit('training/upTrainingDiagnosis', n)
        }
      }
    }
  },

  created() {
    this.ints()
    this._api.training.getTrainingDiagnosis()
    .then(res => {
      if (res.GetResult) {
        res.GetResult.S_OP_Json = this._clJson(res.GetResult.S_OP_Json)
        this.datas = res.GetResult
        this.$store.commit('training/upTrainingDiagnosis', this.datas)
      }else {
        this.datas.S_OP_Json.S_OP_VTFT_ChooseID = this.$store.state.physicianVisits.xzConsulting
        this.datas.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
        this.datas.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
      }
    })
  },

  methods: {
    save() {
      this._api.training.saveTrainingDiagnosis()
      .then(res => {
        // console.log(res)
        if (res.UpdateResult == 1) {
          this.$alert('保存成功！', '提示')
        }
      })
    },
    ints() {
      let arr = []
      for (let i = 1; i < 21; i++) {
        let str = i/100
        arr.push({
          value: str.toString(),
          label: str.toString()
        })
      }
      arr.push({
        value: '0.25',
        label: '0.25'
      })
      arr.push({
        value: '0.3',
        label: '0.3'
      })
      for (let i = 4; i < 11; i++) {
        let str = i / 10
        if (i == 10) str = str + '.0'
        arr.push({
          value: str.toString(),
          label: str.toString()
        })
      }
      arr.push({
        value: '1.2',
        label: '1.2'
      })
      arr.push({
        value: '1.5',
        label: '1.5'
      })
      arr.push({
        value: '2.0',
        label: '2.0'
      })
      this.lysl = arr
    }
  }
}
</script>

<style scoped lang="scss">
  .trainingDiagnosis {
    width: 90vw;
    height: 87vh;
    overflow: hidden;
    overflow-y: auto;
    margin: 0 auto;
    padding-top: 3vh;
  }
  .trainingDiagnosis::-webkit-scrollbar { width: 0 !important }

  .medd-content {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    li {
      width: 12vw;
      //height: 32px;
      //line-height: 32px;
      margin-right: 2%;
    }
    li:nth-child(1) {
      width: 3vw;
      text-align: left;
    }
  }
  .pad-10{padding-top: 15px;}
  .bottom {
    //width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    margin-top: 20px;
    .bzs {
      width: 95%;
    }
    p {width: 5%;text-align: left}
  }

  .yqzd {
    display: flex;
    align-items: center;
    padding-top: 3vh;
    p {padding-right: 2vw;}
    .radio {
      padding-top: 4px;
    }
  }
</style>
